import React from 'react';
import { navigate } from 'gatsby'
import { Box,  Image } from 'grommet';
import { Button } from '@appkit4/react-components/button';
import SidebarIA from '../../components/SidebarIA'

export default function IARobocaller() {
  const onClickButton_IAMyLearning = () => {
    navigate ('/internal-auditor')
  }
  const onClickButton_IAExplore = () => {
    navigate ('/internal-auditor/ia-explore')
  } 
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'100vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarIA />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/4mGk63WBVxP2XhBogvguml/59df432ecf08ec7d08141835f06b0495/ia-my-learning-detail.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} useMap="#discover-new" />
            <Button style={{width: '80px', position: 'absolute', top: '12px', left: '20%'}} kind='text' onClick={onClickButton_IAMyLearning} >&nbsp; &nbsp;<div className='beacon'></div></Button>
            <Button style={{width: '80px', position: 'absolute', top: '12px', left: '30%'}} kind='text' onClick={onClickButton_IAExplore} >&nbsp; &nbsp;<div className='beacon'></div></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}